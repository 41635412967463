<template>
  <div
    id="tour-payroll"
    class="payment-table"
  >
    <div class="d-flex justify-content-between">
      <div>
        <h3>{{ $t('payments.generate-payroll') }} :
          <span class="text-muted ml-2">{{ FORMAT_DATE(formInfo.start_date, 'dddd, MMM D-YYYY') }} - {{ FORMAT_DATE(formInfo.end_date, 'dddd, MMM D-YYYY') }}</span>
        </h3>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-2 text-muted text-center">
          <b>{{ $t('payments.total-time') }}</b> <h3 class="ml-1">
            {{ CONVERT_HM(totalTime) }}
          </h3>
        </div>
        <div class="text-center text-muted">
          <b>{{ $t('payments.total-amount') }}</b> <h3
            class="ml-1"
            style="cursor: pointer;"
            :class="!isAmountBlur ? 'blurry-text' : ''"
            @click="isAmountBlur = !isAmountBlur"
          >
            {{ rows[0] ? rows[0].currency : '-' }} {{ number_format(totalAmount, 2) }}
          </h3>
        </div>

        <manage-permissions
          v-if="canManagePermissions"
          class="ml-2"
          :permissions="permissions"
        />
      </div>
    </div>
    <b-card body-class="p-0">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        class="table-striped table-hover"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'name'">
            <div class="d-flex align-items-center">
              <b-avatar
                :src="getAvatar(props.row.avatar)"
                :variant="getRandomVariant()"
                :text="getInitials(props.row.name)"
              />
              <p class="mb-0 ml-1">{{ props.row.name }}</p>
            </div>
          </span>
          <span v-else-if="props.column.field === 'total_time'">
            {{ CONVERT_HM(props.row.total_time) }}
          </span>
          <span v-else-if="props.column.field === 'pay_rate'">
            <span
              v-if="props.row.pay_rate"
              :class="!props.row.isAmountBlur ? 'blurry-text' : ''"
              @click="props.row.isAmountBlur = !props.row.isAmountBlur"
            >
              {{ props.row.currency }} {{ number_format(props.row.pay_rate,2) }}
              <feather-icon
                v-b-tooltip.hover="`Effective From: ${props.row.effectiveFrom}`"
                icon="InfoIcon"
                class="mb-1"
              />
            </span>

          </span>
          <span v-else-if="props.column.field === 'manual_duration'">
            {{ CONVERT_HM(props.row.manual_duration) }}
          </span>
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex align-items-center"
            :class="!canViewPayment || (!props.row.verifiedAt && !props.row.rejectedAt && props.row.invoicedAt) || ((props.row.verifiedAt || props.row.rejectedAt) && !props.row.paidOn) ? 'justify-content-between' : 'justify-content-end'"
          >
            <div v-if="canViewPayment">
              <div v-if="!props.row.verifiedAt && !props.row.rejectedAt && props.row.invoicedAt">
                <div
                  v-b-tooltip.hover="`Verification Pending by ${props.row.name}`"
                  size="sm"
                  class="pending-badge small bg-warning text-center font-weight-bold rounded-lg text-light"
                  variant="warning"
                >
                  <p class="mb-0">Pending Verification</p>
                </div>
              </div>

              <div v-else-if="props.row.verifiedAt && !props.row.paidOn">
                <div
                  v-b-tooltip.hover="'Payment Pending'"
                  v-b-modal.invoice-history-modal
                  class="pending-badge small bg-warning text-center font-weight-bold rounded-lg text-light"
                  @click="userToShowHistory = props.row"
                >
                  <p class="mb-0">Payment Pending</p>
                </div>
              </div>

              <div v-else-if="props.row.rejectedAt && !props.row.updator">
                <div
                  v-b-tooltip.hover="`Invoice Rejected by ${props.row.name}`"
                  size="sm"
                  class="pending-badge small bg-warning text-center font-weight-bold rounded-lg text-light"
                  variant="danger"
                >
                  <p class="mb-0">Invoice Rejected</p>
                </div>
              </div>
            </div>

            <div>
              <b-button
                id="tour-invoice-history"
                v-b-tooltip.hover="'View Invoice History'"
                v-b-modal.invoice-history-modal
                variant="outline-primary"
                size="sm"
                @click="showInvoiceHistory(props.row)"
              >
                <i class="fa fa-history" />
              </b-button>

              <b-dropdown
                v-if="canEditPayment"
                id="payment-options"
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <b-dropdown-item
                  id="tour-generate-invoice"
                  variant="outline-success"
                  size="sm"
                  @click="showInvoice(props.row)"
                >
                  <i class="fa fa-file-invoice" />
                  {{ $t('payments.generate-invoice') }}
                </b-dropdown-item>

                <b-dropdown-item
                  id="tour-payroll-settings"
                  size="sm"
                  variant="outline-primary"
                  @click="showPaymentSettingsModal(props.row)"
                >
                  <i class="fa fa-cog" />
                  {{ $t('payments.payroll-settings') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </span>
          <span v-else-if="props.column.field === 'total_pay'">
            <span
              v-if="props.row.total_pay !== null"
              :class="!props.row.isAmountBlur ? 'blurry-text' : ''"
              @click="props.row.isAmountBlur = !props.row.isAmountBlur"
            >
              {{ props.row.currency }} {{ number_format(props.row.total_pay,2) }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'payment_method'">
            <b-form-select
              :value="props.row.payment_method"
              :options="paymentMethodOptions"
            />
          </span>
          <span v-else-if="props.column.field === 'paidUpTo'">
            <p class="mb-0">
              {{ timeWithTimeZone(props.row.paidUpTo, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }}
            </p>
          </span>
        </template>
      </vue-good-table>
    </b-card>
    <pagination
      v-if="rows.length"
      :total-items="totalItems"
      :per-page="perPage"
    />
    <generate-invoice
      :visible="showGenerateInvoice"
      :user-info="selectedUserInfo"
      :date-range="formInfo"
      @onSave="handleSave"
      @close="showGenerateInvoice = false; selectedUserInfo = {}"
    />
    <invoice-history
      :user-info="userToShowHistory"
      :date-range="formInfo"
      @close="userToShowHistory = null"
      @refetch="$emit('reloadData')"
    />
    <!-- Payroll Settings -->
    <update-payment-setting
      v-if="selectedUserInfo"
      :user-info="selectedUserInfo"
      @onSave="$emit('reloadData')"
      @close="() => { selectedUserInfo = {} }"
    />
  </div>
</template>
<script>
import {
  BAvatar, BFormSelect, BCard, BButton, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/event-bus'
import Pagination from '@/views/common/components/Pagination.vue'
import { checkPermission } from '@/utils/permissions'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import GenerateInvoice from './GenerateInvoice.vue'
import UpdatePaymentSetting from './UpdatePaymentSetting.vue'
import InvoiceHistory from './InvoiceHistory.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormSelect,
    Pagination,
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    GenerateInvoice,
    InvoiceHistory,
    ManagePermissions,
    UpdatePaymentSetting,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    formInfo: {
      type: Object,
      default: () => {},
    },
    dateRange: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      searchTerm: '',
      columns: [
        { label: this.$t('table.name'), field: 'name' },
        { label: this.$t('payments.total-time'), field: 'total_time' },
        { label: this.$t('Manual Time'), field: 'manual_duration' },
        { label: this.$t('Current Pay Rate'), field: 'pay_rate' },
        { label: this.$t('Billable Amount'), field: 'total_pay', tdClass: 'billable-amount' },
        { label: this.$t('Last Payment'), field: 'paidUpTo' },
        {
          label: this.$t('Action'), field: 'action', sortable: false, width: '250px',
        },
      ],
      isAmountBlur: false,
      perPage: 10,
      totalItems: 0,
      showInvite: false,
      paymentMethodOptions: [
        {
          value: 'manual', text: 'Manual',
        },
        {
          value: 'automatic', text: 'Automatic',
        },
      ],
      showGenerateInvoice: false,
      selectedUserInfo: {},
      userToShowHistory: null,
      permissions: ['payment.edit', 'payment.view'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('project', ['USER_ROLE']),
    ...mapGetters('permissions', ['canViewPayment', 'canEditPayment']),
    totalAmount() {
      return this.rows.reduce((p, c) => p + Number(c.total_pay), 0)
    },
    totalTime() {
      const totalTime = this.rows.reduce((p, c) => p + Number(c.total_time), 0)
      return totalTime
    },
  },
  mounted() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermissions(), 2000)
      }
    })
    this.checkUserPermissions()
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    checkUserPermissions() {
      if (!(this.canViewPayment || this.canEditPayment) || this.needPermissionCheck) {
        checkPermission(this.permissions, permissions => {
          const canEditPayment = permissions['payment.edit'] === true
          const canViewPayment = permissions['payment.view'] === true

          const payload = [
            { key: 'setPaymentViewPermission', value: canViewPayment },
            { key: 'setPaymentEditPermission', value: canEditPayment },
          ]

          this.setPermissions(payload)
          this.needPermissionCheck = false
        })
      }
    },
    showInvoice(user) {
      this.showGenerateInvoice = true
      this.selectedUserInfo = user
    },
    handleSave() {
      this.showGenerateInvoice = false
      this.selectedUserInfo = {}
      this.$emit('reloadData')
    },
    showPaymentSettingsModal(user) {
      this.selectedUserInfo = user
      this.$nextTick(async () => {
        this.$bvModal.show('update-payment-setting-modal')
      })
    },
    async showInvoiceHistory(row) {
      this.userToShowHistory = row
    },
  },
}
</script>
<style>
  .billable-amount {
    font-weight: bold;
  }
  .payment-table .pending-badge {
    width: 96px;
    line-height: 1.4;
    padding: 4px;
  }
</style>
