<template>
  <div>
    <div class="row">
      <b-form-group class="col-4">
        <label>Select User</label>
        <user-search
          v-model="form.userId"
          class="card mr-2"
          placeholder="All Users"
          :multiple="true"
        />
      </b-form-group>
      <b-form-group class="col-4">
        <label class="mr-1">Date Range</label>
        <date-range-filter
          :till-day-before="true"
          @change="dateChange"
        />
      </b-form-group>

      <b-form-group class="col-2">
        <label class="mr-1">Start Date</label>
        <div class="d-flex align-items-center">
          <b-form-datepicker
            v-model="form.start_date"
            :date-format-options="COMMON.DATE_PICKER_FORMAT"
            :max="form.end_date"
            :disabled="!usingCustomDateRange"
          />
        </div>
      </b-form-group>
      <b-form-group class="col-2">
        <label class="mr-1">End Date</label>
        <div class="d-flex align-items-center">
          <b-form-datepicker
            v-model="form.end_date"
            :min="form.start_date"
            :max="maxDate()"
            :disabled="!usingCustomDateRange"
            :date-format-options="COMMON.DATE_PICKER_FORMAT"
            :offset="-80"
          />
        </div>
      </b-form-group>

    </div>

    <b-overlay :show="isProcessing">
      <payment-table
        :rows="rows"
        :form-info="form"
        @reloadData="getPayments"
      />
    </b-overlay>
  </div>
</template>
<script>
import { BFormDatepicker, BFormGroup, BOverlay } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import UserSearch from '@/views/common/components/UserSearch.vue'
import DateRangeFilter from '@/views/common/components/DateRangeFilter.vue'
import { setComponentReady } from '@/views/tour/tour'
import PaymentTable from './components/Table.vue'

const moment = require('moment')

export default {
  components: {
    PaymentTable,
    BFormDatepicker,
    BFormGroup,
    BOverlay,
    UserSearch,
    DateRangeFilter,
  },
  data() {
    return {
      form: {
        start_date: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.maxDate(),
        userId: null,
      },
      rows: [],
      isProcessing: false,
      usingCustomDateRange: false,
    }
  },
  computed: {
    timezoneOffset() {
      return this.$store.state.project.company.timezoneOffset.name
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.getPayments()
      },
    },
  },
  mounted() {
    this.getPayments()
  },

  methods: {
    maxDate() {
      return this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start
    },
    dateChange(range) {
      if (!range.startTime && !range.endTime) {
        this.usingCustomDateRange = true
        return null
      }
      this.form.start_date = range.startTime
      this.form.end_date = range.endTime
      this.usingCustomDateRange = false
      return null
    },
    getPayments() {
      this.isProcessing = true
      const currency = this.$store.state.project.company?.currency ?? '$'
      const projectUUid = this.$store.state.project.selectedProject
      useApollo.payroll
        .getUserPayments({
          first: -1,
          page: 1,
          companyId: this.$store.state.project.selectedCompany,
          startTime: moment(this.form.start_date).format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment(this.form.end_date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
          timezone: this.$store.state.project.company.timezoneOffset.filter,
          projectUUid,
          selectedUser: this.form.userId?.length > 0 ? this.form.userId : null,
        })
        .then(response => {
          this.rows = response.data.users.data.map(payment => ({
            name: payment.name,
            avatar: payment.avatar,
            total_time: payment.activityDuration,
            manual_time: 0,
            currency,
            adjusted_amount: 0,
            pay_rate: payment.paymentSettings?.data[0]?.amount,
            effectiveFrom: this.timeWithTimeZone(
              payment.paymentSettings?.data[0]?.effectiveFrom,
              this.timezoneOffset,
              this.COMMON.DATE_FORMAT,
            ),
            invoicedAt: payment.invoices.data.length ? this.timeWithTimeZone(payment.invoices?.data[0]?.invoicedAt, this.timezoneOffset, this.COMMON.DATE_FORMAT) : null,
            verifiedAt: payment.invoices?.data[0]?.verifiedAt,
            rejectedAt: payment.invoices?.data[0]?.rejectedAt,
            paidOn: payment.invoices?.data[0]?.paidOn,
            total_pay: payment.activityAmount,
            action: 'manual',
            manual_duration: payment.manualDuration,
            uuid: payment.uuid,
            isAmountBlur: false,
            paidUpTo: payment.invoices.data[0]?.to,
            updator: payment.invoices.data[0]?.updator,
          }))
          setComponentReady()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
<style>
.blurry-text {
  color: transparent;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.6); /* Use rgba for better transparency control */
}
</style>
